.story-container {
  position: relative;
  display: flex;
  flex: 1;
}

.flipper-container, .flipper {
  display: flex;
  flex: 1;
}

.flipper-container.active {
  transform: rotateY(180deg);
}

.flipper-container {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  perspective: 1000px;
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.back {
  color: #e2e4e9;
  transform: rotateY(180deg);
}

.story-image-container, .story-text {
  z-index: 2;
}

.story-text-container {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}
.story-text {
  color: #e2e4e9;
  display: flex;
  justify-content: center;
  padding: 3rem;
}

@media(max-width: 767px) {
  .story-image-container {
    width: 100%;
    display: flex;
    opacity: 0.9;
  }
  .story-image {
    height: 100%;
    width: 100%;
    opacity: 0.9;
  }
}

@media(min-width: 431px) {
  .story-image-container {
    min-width: 45%;
    /* height: 100%; */
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
}


.story-image-container > .hatesafari > .our-story-image.fade-in {
  /* height: 100% !important; */
}