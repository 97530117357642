.circle-icon {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    border: 1px solid black;
    margin-right: 15vw;
}
.circle-icon:hover {
  cursor: pointer;
}