@import url(https://fonts.googleapis.com/css?family=Great+Vibes|Poiret+One&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body, #root, #root>div {
  font-family: 'Poiret One', cursive !important;
  font-weight: bold;
  /* min-height: 100vh; */
}

body {
  overflow: auto;
  height: 100vh;
  /* min-height: 100vh; */
  /* margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.non-splash-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.btn:not(disabled).btn:hover, .btn:not(disabled).btn:focus {
  box-shadow: 2px 2px 5px #222;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.shadow-overlay {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -webkit-gradient(linear,left top, left bottom,color-stop(0, transparent),color-stop(40%, transparent),to(rgba(0,0,0,.4)));
    background: -webkit-linear-gradient(top,transparent 0,transparent 40%,rgba(0,0,0,.4) 100%);
    background: linear-gradient(to bottom,transparent 0,transparent 40%,rgba(0,0,0,.4) 100%);
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@media(max-width: 767px) {
  .app-background {
    background-size: 140%;
    background-attachment: scroll;
    background-attachment: initial;
    height: auto;
    width: 100%;
  }
}

@media(min-width: 768px) {
  .app-background {
    background-size: 100%;
  }
}

.content-container {
  display: flex;
  /* flex: 1; */
  justify-content: center;
}

.content-page-enter {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.content-page-enter-active {
  opacity: 0;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms;
}

.content-page-enter-done {
  opacity: 1;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

.content-page-exit {
  opacity: 0.5;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.content-page-exit-active {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms;
}

.full-page {
  display: flex;
  /* flex: 1 1 100%; */
}

.full-page > div {
  display: flex;
  /* flex: 1 */
  width: 100%;
}

.grey-overlay {
  position: absolute;
  opacity: 0.5;
  background: grey;
  height: 100%;
  width: 100%;
  z-index: 0 !important;
}

.section-header {
  text-align: center;
}

.text-content-section {
  margin-bottom: 2rem;
  color: #e2e4e9;
}

.section-container {
  color: #e2e4e9;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1 1
}

.info-list-container {
  margin-left: 15px;
}

.cursor-pointer {
  cursor: pointer;
}
.navbar-container {
  width: 100%;
  background-color: transparent;
  color: #e2e4e9;
}

.navbar-nav-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.navbar-nav-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.navbar-nav-exit {
  opacity: 1;
}

.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: black;
  opacity: 0.5;
}

.navbar-nav {
  font-size: 22px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #e2e4e9;
}

.navbar-name-header {
  margin: 0;
  font-family: 'Great Vibes', cursive;
  font-size: 5rem;
  line-height: unset;
  color: #d4d1f1;
}

@media(max-width: 767px) {
  .navbar-container {
    z-index: 5;
  }
  .navbar-name-header {
    display: none;
  }
  .navbar-nav {
    background-color: transparent;
    border-radius: 4px;
    position: absolute;
    right: 1rem;
    z-index: 1000;
    text-align: right;
    overflow: scroll;
    height: 70vh;
  }
  .nav-item.active {
    background-color: lightgrey;
  }
  .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media(min-width: 768px) {
  .navbar {
    flex-direction: column !important;
  }
}

.navbar-nav-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.navbar-subtext {
  display: flex;
  flex-direction: column;
  color: #e2e4e9;
  font-weight: bold;
  padding: 0.5rem 1rem;
  position: absolute;
}
.navbar-subtext > span:first-child {
  font-size: 12px;
}
.navbar-subtext > span:last-child {
  font-size: 12px;
}
.home-body {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  width: 100%;
  position: absolute;
}
@-webkit-keyframes fadein {
  0% { opacity: 0 }
  100% { opacity: 1}
}

@keyframes fadein {
  0% { opacity: 0 }
  100% { opacity: 1}
}

.image-loader-container {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
}

.background-image {
  flex: 1 1;
  -webkit-animation: fadein .5s linear;
          animation: fadein .5s linear;
}

@media(min-width: 768px) {
  .background-image {
    background-size: 100% 100%;
    align-self: stretch;
  }
}
@media(max-width: 767px) {
  .background-image {
    background-size: cover;
  }
}
@-webkit-keyframes fadein {
  0% { opacity: 0 }
  100% { opacity: 1}
}

@keyframes fadein {
  0% { opacity: 0 }
  100% { opacity: 1}
}

.our-story-image {
  flex: 1 1;
  -webkit-animation: fadein .5s linear;
          animation: fadein .5s linear;
  width: 100%;
}
@-webkit-keyframes slide-in-left {
	0% { -webkit-transform: translateX(-100%); transform: translateX(-100%) }
	100% { -webkit-transform: translateY(0%); transform: translateY(0%) }
}

@keyframes slide-in-left {
	0% { -webkit-transform: translateX(-100%); transform: translateX(-100%) }
	100% { -webkit-transform: translateY(0%); transform: translateY(0%) }
}

@-webkit-keyframes slide-in-right {
	0% { -webkit-transform: translateX(100%); transform: translateX(100%) }
	100% { -webkit-transform: translateX(0%); transform: translateX(0%) }
}

@keyframes slide-in-right {
	0% { -webkit-transform: translateX(100%); transform: translateX(100%) }
	100% { -webkit-transform: translateX(0%); transform: translateX(0%) }
}

@media(min-width: 768px) {
	.wedding-party-item-image {
		height: 150px;
		width: 150px;
	}
}

@media(max-width: 767px) {
	.wedding-party-item-image {
		height: 75px;
		width: 75px;
	}
	.wedding-party-item-info {
		text-align: center;
	}
	.wedding-party-item-container {
		display: block !important;
	}
}

.wedding-party-item {
	visibility: visible;
	min-height: 12rem;
	border: 1px solid black;
	position: relative;
}

.wedding-party-visible-left {
	opacity: 1;
	-webkit-animation: slide-in-left 0.3s ease;
	        animation: slide-in-left 0.3s ease;;
}

.wedding-party-visible-right {
	opacity: 1;
	-webkit-animation: slide-in-right 0.3s ease;
	        animation: slide-in-right 0.3s ease;
}

.not-visible {
	visibility: hidden !important;
}

.wedding-party-item-content {
	padding: 1rem;
	display: flex;
	width: 100%;
}
.wedding-party-list-container li {
	list-style-type: none;
	width: 90%;
	margin-top: 4px;
	margin-bottom: 4px;
}

.our-story-image {
	height: auto !important;
}
.arrow {
  text-align: center;
  margin: 5% 0;
  position: absolute;
  z-index: 1;
  left: 50%;
}
.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.arrow-down {
  color: white;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}
.story-container {
  position: relative;
  display: flex;
  flex: 1 1;
}

.flipper-container, .flipper {
  display: flex;
  flex: 1 1;
}

.flipper-container.active {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flipper-container {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.back {
  color: #e2e4e9;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.story-image-container, .story-text {
  z-index: 2;
}

.story-text-container {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}
.story-text {
  color: #e2e4e9;
  display: flex;
  justify-content: center;
  padding: 3rem;
}

@media(max-width: 767px) {
  .story-image-container {
    width: 100%;
    display: flex;
    opacity: 0.9;
  }
  .story-image {
    height: 100%;
    width: 100%;
    opacity: 0.9;
  }
}

@media(min-width: 431px) {
  .story-image-container {
    min-width: 45%;
    /* height: 100%; */
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
}


.story-image-container > .hatesafari > .our-story-image.fade-in {
  /* height: 100% !important; */
}
.gallery-link {
  margin-right: 4rem;
  cursor: pointer;
  font-size: 1.2rem;
}
.gallery-link.active {
  color: black;
  cursor: default;
}
.gallery-link:hover:not(.active) {
  color: black;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform .05s linear;
  transition: -webkit-transform .05s linear;
  transition: transform .05s linear;
  transition: transform .05s linear, -webkit-transform .05s linear;
}
.gallery-link:last-child {
  margin-right: 0;
}
.venmo-image-container {
  height: 250px;
  width: 250px;
}
@-webkit-keyframes fade {
  0% { opacity: 0 }
  25% { opacity: .25 }
  50% { opacity: .50 }
  100% { opacity: 1 }
}


@keyframes fade {
  0% { opacity: 0 }
  25% { opacity: .25 }
  50% { opacity: .50 }
  100% { opacity: 1 }
}


.rsvp-container {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.rsvp-form-container {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  position: relative;
  /* padding: 1rem;
  width: 60%;
  height: auto;
  max-height: 75%; */
}

@media(max-width: 767px) {
  .rsvp-form-container {
    max-width: 80%;
    flex: 1 1;
    background-position: unset !important;
  }
}

.rsvp-form-btn {

  display: flex;
  justify-content: center;
}

.rsvp-form-title {
  padding: 1.5rem;
  text-align: center;
  color: white;
  font-size: 1.5rem;
}

.rsvp-form {
  padding: 2rem;
}

ul, li {
  margin: 0;
  padding: 0;
}

.guest-list-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem;
  list-style: none;
}

.guest-list:last-child {
  border-top: none;
}

.guest-list-item, .no-result-list-item {
  border: 1px solid black;
  -webkit-animation: fade 0.5s ease;
          animation: fade 0.5s ease;
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  display: flex !important;
  align-items: center;

}

.guest-name {
  padding-right: 5px;
}

.guest-rsvp-btn {
  margin-left:auto;
  margin-right: 2px;
}

.list-group-item {
  padding: 2rem 1.25rem !important;
}

@media(max-width: 767px) {
  .list-group-item {
    padding: 1.75rem .5rem !important;
    flex: 1 1;
  }
  /* .guest-list-container {
    overflow-y: scroll;
  } */
  /* .rsvp-form-container {
    height: 100%;
    max-height: 100%;
  } */
}
.circle-icon {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    border: 1px solid black;
    margin-right: 15vw;
}
.circle-icon:hover {
  cursor: pointer;
}
.summary-content {
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
.circle-icon-container {
  display: flex;
}

.label-input {
  margin-bottom: 0px;
}

.modal-footer {
  border-top: none !important;
}

.dismiss-container {
  position: absolute;
  top: 5px;
  right: 20px;
}

.dismiss {
  border: none;
  background-color: transparent;
}
.faq-list-container {
  list-style: none;
}

.faq-icon {
  margin-right: 10px;
}

.faq-list-item:hover {
  text-decoration: underline;
}
.faq-list-item--detail {
  margin-left: 2rem;
  margin-top: 1rem;
}

