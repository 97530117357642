@media(max-width: 767px) {
  .app-background {
    background-size: 140%;
    background-attachment: initial;
    height: auto;
    width: 100%;
  }
}

@media(min-width: 768px) {
  .app-background {
    background-size: 100%;
  }
}

.content-container {
  display: flex;
  /* flex: 1; */
  justify-content: center;
}

.content-page-enter {
  opacity: 0;
  transform: translateX(100%);
}

.content-page-enter-active {
  opacity: 0;
  transform: translateX(0%);
  transition: opacity 200ms, transform 200ms;
}

.content-page-enter-done {
  opacity: 1;
  transition: opacity 200ms;
}

.content-page-exit {
  opacity: 0.5;
  transform: translateX(0%);
}

.content-page-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 200ms, transform 200ms;
}

.full-page {
  display: flex;
  /* flex: 1 1 100%; */
}

.full-page > div {
  display: flex;
  /* flex: 1 */
  width: 100%;
}

.grey-overlay {
  position: absolute;
  opacity: 0.5;
  background: grey;
  height: 100%;
  width: 100%;
  z-index: 0 !important;
}

.section-header {
  text-align: center;
}

.text-content-section {
  margin-bottom: 2rem;
  color: #e2e4e9;
}

.section-container {
  color: #e2e4e9;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1
}

.info-list-container {
  margin-left: 15px;
}

.cursor-pointer {
  cursor: pointer;
}