.gallery-link {
  margin-right: 4rem;
  cursor: pointer;
  font-size: 1.2rem;
}
.gallery-link.active {
  color: black;
  cursor: default;
}
.gallery-link:hover:not(.active) {
  color: black;
  transform: scale(1.1);
  transition: transform .05s linear;
}
.gallery-link:last-child {
  margin-right: 0;
}