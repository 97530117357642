.navbar-container {
  width: 100%;
  background-color: transparent;
  color: #e2e4e9;
}

.navbar-nav-enter {
  opacity: 0;
  transform: scale(0.9);
}

.navbar-nav-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.navbar-nav-exit {
  opacity: 1;
}

.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: black;
  opacity: 0.5;
}

.navbar-nav {
  font-size: 22px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #e2e4e9;
}

.navbar-name-header {
  margin: 0;
  font-family: 'Great Vibes', cursive;
  font-size: 5rem;
  line-height: unset;
  color: #d4d1f1;
}

@media(max-width: 767px) {
  .navbar-container {
    z-index: 5;
  }
  .navbar-name-header {
    display: none;
  }
  .navbar-nav {
    background-color: transparent;
    border-radius: 4px;
    position: absolute;
    right: 1rem;
    z-index: 1000;
    text-align: right;
    overflow: scroll;
    height: 70vh;
  }
  .nav-item.active {
    background-color: lightgrey;
  }
  .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media(min-width: 768px) {
  .navbar {
    flex-direction: column !important;
  }
}

.navbar-nav-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.navbar-subtext {
  display: flex;
  flex-direction: column;
  color: #e2e4e9;
  font-weight: bold;
  padding: 0.5rem 1rem;
  position: absolute;
}
.navbar-subtext > span:first-child {
  font-size: 12px;
}
.navbar-subtext > span:last-child {
  font-size: 12px;
}