.circle-icon-container {
  display: flex;
}

.label-input {
  margin-bottom: 0px;
}

.modal-footer {
  border-top: none !important;
}

.dismiss-container {
  position: absolute;
  top: 5px;
  right: 20px;
}

.dismiss {
  border: none;
  background-color: transparent;
}