@keyframes fade {
  0% { opacity: 0 }
  25% { opacity: .25 }
  50% { opacity: .50 }
  100% { opacity: 1 }
}


.rsvp-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.rsvp-form-container {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  position: relative;
  /* padding: 1rem;
  width: 60%;
  height: auto;
  max-height: 75%; */
}

@media(max-width: 767px) {
  .rsvp-form-container {
    max-width: 80%;
    flex: 1;
    background-position: unset !important;
  }
}

.rsvp-form-btn {

  display: flex;
  justify-content: center;
}

.rsvp-form-title {
  padding: 1.5rem;
  text-align: center;
  color: white;
  font-size: 1.5rem;
}

.rsvp-form {
  padding: 2rem;
}

ul, li {
  margin: 0;
  padding: 0;
}

.guest-list-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem;
  list-style: none;
}

.guest-list:last-child {
  border-top: none;
}

.guest-list-item, .no-result-list-item {
  border: 1px solid black;
  animation: fade 0.5s ease;
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  display: flex !important;
  align-items: center;

}

.guest-name {
  padding-right: 5px;
}

.guest-rsvp-btn {
  margin-left:auto;
  margin-right: 2px;
}

.list-group-item {
  padding: 2rem 1.25rem !important;
}

@media(max-width: 767px) {
  .list-group-item {
    padding: 1.75rem .5rem !important;
    flex: 1;
  }
  /* .guest-list-container {
    overflow-y: scroll;
  } */
  /* .rsvp-form-container {
    height: 100%;
    max-height: 100%;
  } */
}